<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="box" style="margin-top:10px; border: none!important;">
                        <!--Main Start-->
                        <div v-if="success">
                            <div class="col-md-12" style="margin-bottom:20px;">
                                <h3>Congratulations !!!</h3>
                                <p class="alert alert-success">Your account was activated.<br />
                                    Now you can login and have fun.
                                </p>
                                <router-link to="/login" class="btn btn-default">
                                    Login
                                </router-link>
                            </div>
                        </div>
                        <div v-if="error">
                            <div class="col-md-12" style="margin-bottom:20px;">
                                <h3>Activation failed</h3>
                                <p class="alert alert-danger">Your account can't be activated.<br />
                                    Please contact our support to help you activate your account.
                                </p>
                                <router-link to="/contact" class="btn btn-default">
                                    Contact Form
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
</template>

<script>
import { ref } from 'vue';
import { activateUser } from '@/app/api/user';
import { useUrl } from '@/app/router';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Activation',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
  setup() {
    const { uuid } = useUrl();
    const success = ref(false);
    const error = ref(false);

        activateUser(uuid.value)
      .then(() => {
        success.value = true;
      })
      .catch(() => {
        error.value = true;
      });

    return {
        success,
      error
    };
  },
};
</script>
